import styled from "styled-components";
import { motion } from "framer-motion";

export const ButtonWhatsApp = styled(motion.div)`
    a {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 20px;
        right: 20px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 1px 1px 2px #888;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center

    }
`