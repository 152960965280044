import { GlobalStyles } from './styles/globalstyles'
import { BiPhoneCall } from 'react-icons/bi';
import { MdOutlineMail } from 'react-icons/md';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

import {
  Container, Header, Logo, Main, FirstTitle, SecondTitle, Subtitle, DivButton, DivIcons, DivLogo,  DivSocial,TitleLogo, SubTitleLogo, SectionFooter, CopyRight, InfoCompany, TitleInfoCompany, ContactCompany, ContainerContactCompany, ContainerLogoFooter, DivLogoFooter, LogoFooter, TitleLogoFooter, SubTitleLogoFooter, ContainerTextLogo, SectionAbout, ContainerTextAbout
} from './styles/styles'

import Icon from './components/Icon';
import Button from './components/Button';
import pixelToRem from './utils/pxToRem';
import GalleryReact from './components/TabMenu/GalleryReact';
import FloatingButtonWhatsApp from './components/FloatingButtonWhatsApp';

function App() {

  return (
    <>
      <Container flex='column' margin={pixelToRem(24, 112, 50)}>
        <GlobalStyles />
        <Header>
          <DivLogo>
            <Logo />
          </DivLogo>
          <ContainerTextLogo>
          <TitleLogo>EXTINCENTER</TitleLogo>
            <SubTitleLogo>
              Comercio e recarga de extintores
            </SubTitleLogo>
            <SubTitleLogo>
              Equipamentos e Recarga de Combate a Incêndio
            </SubTitleLogo>
          </ContainerTextLogo>

        </Header>

        <Main>
          <FirstTitle>Qualidade, Preço justo e atendimento de qualidade</FirstTitle>

          <SecondTitle>Sua segurança em boas mãos</SecondTitle>

          <Subtitle>Não deixe sua empresa exposta a riscos desnecessários. Nossos extintores de incêndio estão sempre disponíveis para manutenção e recarga para garantir que estejam sempre prontos para uso.
          </Subtitle>
          <DivButton>
            <Button text="PEDIR UM ORÇAMENTO" fullWidth />
          </DivButton>

        </Main>

        <DivIcons
          whileInView="visible"
          initial="initial"
          viewport={{ once: true }}
          variants={{
            initial: { opacity: 0, y: 10 },
            visible: {
              opacity: 1,
              y: 0,
              transition: { duration: 1, delay: 0.3 },
            },
          }}
        >
          <Icon src="/images/icons-just-price.svg" txt="Temos preço justo" alt="Rocket" />

          <Icon src="/images/icon-support-agent.svg" txt="Ótimo atendimento" alt="Flag" />

          <Icon src="/images/icon-atendente.svg" txt="Atendemos indústrias, empresas, comércios, condomínios entre outros " alt="Telescope" />
          
          <Icon src="/images/icon-engineering.svg" txt="Experiência no ramo e profissionais qualificados" alt="Telescope" />

        </DivIcons>
      </Container>
      <FloatingButtonWhatsApp />
      <SectionAbout
         whileInView="visible"
         initial="initial"
         viewport={{ once: true }}
         variants={{
           initial: { opacity: 0, y: 10 },
           visible: {
             opacity: 1,
             y: 0,
             transition: { duration: 1, delay: 0.3 },
           },
         }}
      >
          <img src="/images/company/extincenter-fachada.webp" alt="Fachada extincenter" ></img>
        <ContainerTextAbout>
          <h1 className='titleAbout'>QUEM SOMOS ?</h1>
          <h1>Somos referência quando se trata de extintores e prevenção contra incêndio no estado de Mato Grosso.</h1>
          <p>Somos uma empresa com mais de 20 anos no mercado com vasta experiência em combate a incêndio.
            Temos tudo o que você precisa para combate a incêndio. Nosso diferencial está em agilidade no atendimento, contando com técnicos especializados e treinados.
            A Extincenter Extintores oferece o que há de melhor, com técnicos e profissionais capacitados, desde recargas de extintores até manutenção e instalação do sistema de combate a incêndio.
          </p>
        </ContainerTextAbout>
      </SectionAbout>
      <GalleryReact />

      <SectionFooter>
        <ContainerLogoFooter>
          <DivLogoFooter>
            <LogoFooter />
          </DivLogoFooter>
          <ContainerTextLogo>
            <TitleLogoFooter>EXTINCENTER</TitleLogoFooter>
            <SubTitleLogoFooter>
              Comercio e recarga de extintores
            </SubTitleLogoFooter>
            <SubTitleLogoFooter>
              Equipamentos e Recarga de Combate a Incêndio
            </SubTitleLogoFooter>
          </ContainerTextLogo>
        </ContainerLogoFooter>
        <InfoCompany>
          <ContainerContactCompany>
            <TitleInfoCompany>Endereço</TitleInfoCompany>
              <strong>Extincenter Extintores</strong>  
              <span>R. São Bento, 21 - Centro Norte, Várzea Grande - MT, 78110-650</span>
          </ContainerContactCompany>

          <ContainerContactCompany>
              <TitleInfoCompany>Contato</TitleInfoCompany>

              <ContactCompany>
                <BiPhoneCall size={20} /> 
                <a href="tel:65981485455">{'(65) 9 8148-5455 '}</a>
                <span>/</span>
                <a href="tel:6536820700">{' (65) 3682-0700'}</a>
              </ContactCompany>

              <ContactCompany>
                <MdOutlineMail size={20} />
                <a href="mailto:extincentermt@gmail.com">extincentermt@gmail.com</a>
              </ContactCompany>
          </ContainerContactCompany>
        </InfoCompany>
      <CopyRight>
        <DivSocial>
          <a href="https://www.instagram.com/extincenterextintoresoficial/" rel="noreferrer" target="_blank">
            <FaInstagram size={25} />
          </a>
          <a href="https://www.facebook.com/extincenterextintoresoficial" rel="noreferrer" target="_blank">
            <FaFacebookSquare size={25} />
          </a>
        </DivSocial>
        <span>© Copyright EXTINCENTER COMERCIO E RECARGA DE EXTINTORES LTDA - CNPJ: 03.551.500/0001-00</span>
      </CopyRight>
      </SectionFooter>
    </>

  );
}

export default App;
