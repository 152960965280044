import { ButtonComponent } from './styles';
import { FaWhatsapp } from 'react-icons/fa';
import { IconType } from 'react-icons';


export interface ButtonProps {
  text?: string;
  fullWidth?: boolean;
  icon?: IconType;
};

function Button({ text, fullWidth}: ButtonProps) {
  function handleRequestQuote() {
    window.location.href = 'https://bit.ly/3CpWfO2'
  }
  return (
    <>
        <ButtonComponent
          fullWidth={fullWidth}
          onClick={handleRequestQuote}
          >
          <FaWhatsapp />

          {text}
        </ButtonComponent>
    </>
  );
}

export default Button;
