import styled from "styled-components";
import pixelToRem from "../utils/pxToRem";
import { device } from "./responsive";
import { motion } from "framer-motion";

interface GalleryFlexContainerProps {
  flex?: "row" | "column";
  width?: string;
  margin?: string;
  padding?: string;
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
}

interface ImageGalleryProps {
  width?: number;
  height?: number;
  src: string;
  borderRadius?: number;
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
}

export const Container = styled.div<GalleryFlexContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};

  @media ${device.mobile} {
    margin: ${pixelToRem(17)};
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: ${pixelToRem(45)};

  @media ${device.mobile} {
    padding-bottom: ${pixelToRem(15)};
  }
`;

export const Logo = styled.image<GalleryFlexContainerProps>`
  width: ${pixelToRem(130)};
  height: ${pixelToRem(150)};
  background-image: url("/images/company/logo-extincenter-sem-fundo-completed.webp");
  background-repeat: no-repeat;

  @media ${device.mobile} {
    width: ${pixelToRem(80)};
    background-size: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TitleLogo = styled.div`
  color: var(--text);
  font: var(--font-display);
  text-align: center;
  letter-spacing: ${pixelToRem(5)};

  @media ${device.mobile} {
    font: var(--font-mobile-heading-1);
    letter-spacing: ${pixelToRem(1)};
    padding-bottom: ${pixelToRem(0)};
    text-align: center;
    margin-left: 0.5rem;

  }
`

export const SubTitleLogo = styled.div`
  color: var(--text);
  font: var(--font-heading-4);
  letter-spacing: ${pixelToRem(2)};
  text-align: center;

  @media ${device.mobile} {
    font: var(--font-mobile-text-1);
    letter-spacing: ${pixelToRem(0)};
    padding-bottom: ${pixelToRem(0)};
    text-align: center;
    font-size: 0.7rem;
    line-height: -10px;

  }
`


export const Main = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${pixelToRem(815)};
  padding-bottom: ${pixelToRem(10)};

  @media ${device.mobile} {
    max-width: 100vw;
    align-items: center;
    justify-content: center;
    padding-bottom: ${pixelToRem(5)};

  }
`;

export const FirstTitle = styled.div`
  color: var(--sun);
  font: var(--text-4);
  text-transform: uppercase;
  letter-spacing: ${pixelToRem(5)};

  @media ${device.mobile} {
    font: var(--font-mobile-text-1);
    letter-spacing: ${pixelToRem(5)};
    padding-bottom: ${pixelToRem(25)};
    text-align: center;

  }
`;

export const SecondTitle = styled.p`
  color: var(--text);
  font-weight: bold;
  font-size: 3.5rem;

  @media ${device.mobile} {
    font: var(--font-mobile-heading-1);
    padding-bottom: ${pixelToRem(25)};
    text-align: center;
  }

  span {
    color: var(--mars);
  }
`;

export const Subtitle = styled.p`
  color: var(--gray-05);
  font: var(--font-heading-3);
  max-width: ${pixelToRem(728)};

  @media ${device.mobile} {
    padding: ${pixelToRem(16, 0, 32, 0)};
    text-align: center;
  }
`;

export const SectionAbout = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: url("/images/background-fogo-tres-exposure.webp");
  background-position: top right;
  color: var(--text);
  padding: 1.5rem;

  .titleAbout {
    color: red; 
    font: var(--text-4);
  }

  img {
    width: 600px;
    height: 650%;
    margin-top: 2rem;
  }

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 400px;
      height: 450%;
      margin-top: 0;
    }

    .titleAbout {
      margin-top: 2rem;
    }
  }
`
export const ImageExtincenter = styled.div`
  background: url("/images/background-fogo-tres.jpg") no-repeat;
`

export const ContainerTextAbout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 1.5rem 0;
  margin: 0 3rem;

  @media ${device.mobile} {
    margin: 0;
    padding: 0;
  }


`

export const DivButton = styled.div`
  width: ${pixelToRem(264)};
  padding-top: ${pixelToRem(10)};

  @media ${device.mobile} {
    padding-top: ${pixelToRem(22)};
    padding-bottom: ${pixelToRem(52)}; 
  }
`;

export const DivIcons = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  max-width: ${pixelToRem(1140)};
  align-items: center;
  width: 100%;

  @media ${device.mobile} {
    flex-direction: column;
    text-align: center;
    gap: ${pixelToRem(64)};
    padding-bottom: ${pixelToRem(72)};
    max-width: ${pixelToRem(222)};

    & image {
      margin: 0 auto;
    }
  }
`;

export const ContainerGallery = styled(motion.div)`
`

export const ImageMars = styled(motion.image)`
  width: ${pixelToRem(621)};
  height: ${pixelToRem(621)};
  background-image: url("/images/mars.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
`;

export const DivAboutMars = styled(motion.div)<GalleryFlexContainerProps>`
  max-width: ${pixelToRem(603)};
`;

export const SecondSubTitle = styled.p`
  font: var(--font-heading-1);
  color: var(--text);
  padding-top: ${pixelToRem(14)};
`;

export const TextMars = styled.p`
  font: var(--text-1);
  color: var(--gray-05);
  padding-top: ${pixelToRem(26)};
`;

export const GalleryContent = styled.div<GalleryFlexContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  margin: ${pixelToRem(150, 100, 157, 300)};
`;

export const DivLogo = styled(motion.div)<GalleryFlexContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;

  @media ${device.mobile} {
    padding-left: 0;
  }

`;

export const DivLogoSpaceY = styled.div`
  display: flex;
  padding-bottom: ${pixelToRem(13)};
`;

export const ImageGallery = styled.img<ImageGalleryProps>`
  width: ${({ width }) => width && pixelToRem(width)};
  height: ${({ height }) => height && pixelToRem(height)};
  border-radius: ${({ borderRadius }) =>
    borderRadius && pixelToRem(borderRadius)};
  object-fit: ${({ objectFit }) => objectFit};
`;

export const ContainerAbout = styled.div<GalleryFlexContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  width: ${(props) => props.width};
  margin-top: ${pixelToRem(180)};
  gap: ${pixelToRem(200)};
`;

export const TextLogo = styled.p`
  font: var(--font-heading-1);
  color: var(--text);
`;

export const TextSubscribe = styled(motion.p)`
  font: var(--text-3);
  color: var(--mars-light);
  cursor: pointer;
  padding-top: ${pixelToRem(20)};
`;

export const ContainerForm = styled.div`
  display: flex;
  width: 100%;
  background: url("/images/background-stars-form.jpg") no-repeat;
  background-size: cover;
  justify-content: space-around;
`;

export const SectionForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: url("/images/background-stars-form.jpg") no-repeat;
  background-size: cover;
  justify-content: space-around;

  /* @media ${device.mobile} {
    display: none;
  } */
`;

export const DivForm = styled(motion.div)`
  background: var(--background-form);
  border-radius: ${pixelToRem(20)};
  align-items: center;
  justify-content: center;
  padding: ${pixelToRem(39, 51, 61, 52)};
  margin-left: ${pixelToRem(120)};
  max-height: ${pixelToRem(792)};
`;
export const IconForm = styled.div`
  background: url("/images/icon-ticket.svg") no-repeat;
  width: ${pixelToRem(56)};
  height: ${pixelToRem(56)};
`;
export const TitleForm = styled.div`
  font: var(--font-heading-2);
  color: var(--text);
  max-width: ${pixelToRem(264)};
  padding-top: ${pixelToRem(16)};
`;
export const SubtitleForm = styled.div`
  font: var(--heading-3);
  color: var(--gray-05);
  max-width: ${pixelToRem(308)};
`;

export const DivRocketImage = styled(motion.div)`
  display: flex;
`;

export const RocketImage = styled(motion.image)`
  width: ${pixelToRem(980)};
  height: ${pixelToRem(980)};
`;

export const DivInputCheckbox = styled.div`
  display: flex;
  padding: ${pixelToRem(24, 0, 32, 0)};
`;

export const InputCheckbox = styled.input.attrs({ type: "checkbox" })`
  width: ${pixelToRem(24)};
  height: ${pixelToRem(24)};
  border: 1px solid var(--gray-05);
  margin-right: ${pixelToRem(16)};
  appearance: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  content: "";

  &:checked {
    background-color: var(--mars);
  }
`;

export const TextCheckbox = styled.p`
  font: var(--text-1);
  color: var(--gray-05);
`;

export const DivImageSmoke = styled(motion.div)`
  width: 100%;

  & img {
    width: 100%;
  }
`;

export const DivFooterBottom = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const DivSocial = styled.div`
  display: flex;
  gap: ${pixelToRem(20)};
  justify-content: center;
  margin-top: 2rem;

  @media ${device.mobile} {
    margin-top: 1rem;
  }

  & img {
    width: ${pixelToRem(25)};
    height: ${pixelToRem(25)};
  }


  svg {
    color: var(--text);


    &:hover {
      color: var(--sun);

    }
  }
  &:hover {
    cursor: pointer;
  }

  a:hover {
    color: red;
  }
`;

export const SectionFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  padding: 1.5rem 6.5rem;
  color: var(--text);

  @media ${device.mobile} {
    padding: 1.5rem 1.5rem;
  }
`;

export const ContainerLogoFooter = styled.div`
  display: flex;
  width: 100%;

  @media ${device.mobile} {
    justify-content: center;
  }
`

export const DivLogoFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoFooter = styled.div`
  width: ${pixelToRem(60)};
  height: ${pixelToRem(60)};
  background-image: url("/images/company/logo-extincenter-sem-fundo-completed.webp");
  background-repeat: no-repeat;
  background-size: 3.125rem;
  

  @media ${device.mobile} {
    background-size: 2.5rem;
    width: ${pixelToRem(50)};
    height: ${pixelToRem(50)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ContainerTextLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
`

export const TitleLogoFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text);
  font: var(--font-heading-1);
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.4rem;
  
`

export const SubTitleLogoFooter = styled.div`
  color: var(--text);
  font: var(--text-2);
  font-size: 0.6rem;
  text-align: center;
  font-weight: normal;
  line-height: 1;
`

export const InfoCompany = styled.div`
  color: var(--text);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;
  
`

export const CopyRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border-top: 1px solid var(--text);

  @media ${device.mobile} {
    font: var(--font-mobile-text-1);
    font-size: 0.75rem;
  }
`

export const ContainerContactCompany = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  a {
    text-decoration: none;
    color: var(--text);
    transition: filter 0.2s;

    &:hover {
      color: var(--sun);
      filter: brightness(0.8);
    }
  }

  @media ${device.mobile} {
    span {
      font-size: 0.80rem;
    } 
  }


`

export const TitleInfoCompany = styled.div`
  font-size: 1.5rem;
  color: var(--sun);
  font-weight: bold;

  strong {
    color: var(--text);
    font-size: 0.875rem;
  }

  span {
    color: var(--text);
    font-weight: normal;
    font-size: 0.875rem;
  }
  
`

export const ContactCompany = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 0.875rem;
  
  svg {
    margin-right: 0.5rem;
    color: var(--sun);
  }
`

export const DivFooterMenu = styled.div`
  gap: ${pixelToRem(53)};

  & ul {
    display: flex;
    list-style: none;
    gap: ${pixelToRem(53)};
  }

  & a {
    color: var(--text);
    font: var(--text-3);
    text-decoration: none;
  }
`;
