import React, {useState} from 'react'
import { ContainerGallery } from '../../styles/styles';
import "../TabMenu/tab.css";
import Menu from "./menu";

function GalleryReact() {

    const [items, setItems] = useState(Menu);
    const [isCategorySelected, setIsCategorySelected] = useState("Todos");
    
    const filterItem = (categItem: any) => {
        const updatedItems = Menu.filter((curElem) => {
            setIsCategorySelected(categItem);
            return curElem.category === categItem;
        });

        if (categItem !== "Todos") {
            setItems(updatedItems);
        }

    }

    console.log(items)


    return (
        <ContainerGallery
            whileInView="visible"
            initial="initial"
            viewport={{ once: true }}
            variants={{
            initial: { opacity: 0, y: 10 },
            visible: {
                opacity: 1,
                y: 0,
                transition: { duration: 1, delay: 0.3 },
            },
            }}
            className="containerGallery"
        >
            <h1 className="pt-5 text-center main-heading title-section">PRODUTOS</h1>
            <hr />
            
            <div className="menu-tabs container">
                <div className="menu-tab row">
                    <div className="col-lg-12 align-self-center text-center">
                        <button 
                            className="btn btn-danger" 
                            style={{ background: isCategorySelected === "Todos" ? 'var(--sun)' : '#fa3757' }} 
                            onClick={() => { 
                            setItems(Menu) 
                            filterItem('Todos')  
                            }}>
                            Todos
                        </button>
                        <button className="btn btn-danger" style={{ background: isCategorySelected === "Recarga Extintores" ? 'var(--sun)' : '#fa3757' }} onClick={() => filterItem('Recarga Extintores')}>Recarga Extintores</button>
                        <button className="btn btn-danger" style={{ background: isCategorySelected === "Extintores" ? 'var(--sun)' : '#fa3757' }} onClick={() => filterItem('Extintores')}>Extintores</button>
                        <button className="btn btn-danger" style={{ background: isCategorySelected === "Rede de Hidrante" ? 'var(--sun)' : '#fa3757' }}  onClick={() => filterItem('Rede de Hidrante')}>Rede de Hidrante</button>
                        <button className="btn btn-danger" style={{ background: isCategorySelected === "Sinalização Extintores" ? 'var(--sun)' : '#fa3757' }} onClick={() => filterItem('Sinalização Extintores')}>Sinalização Extintores</button>
                        <button className="btn btn-danger" style={{ background: isCategorySelected === "Placa Rota Fuga" ? 'var(--sun)' : '#fa3757' }} onClick={() => filterItem('Placa Rota Fuga')}>Placa Rota Fuga</button>
                        <button className="btn btn-danger" style={{ background: isCategorySelected === "Suporte para Extintores" ? 'var(--sun)' : '#fa3757' }} onClick={() => filterItem('Suporte para Extintores')}>Suporte para Extintores</button>
                    </div>

                </div>
            </div>

            {/* my main items section  */}
            <div className="menu-items container-fluid">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="row my-5">
                            
                            {
                                items.map((elem) => {
                                    const { name, image, description } = elem;

                                    return (
                                    
                                        <div className="item1 col-12 col-md-6 col-lg-6 mb-5">
                                            <div className="row Item-inside" style={{ marginBottom: 180 }}>
                                                {/* for images */}
                                                <div className="col-12 col-md-12 col-lg-4 img-div">
                                                    <img src={image} alt={name} className="img-fluid"/>
                                                </div>

                                                {/* menu items description */}
                                                <div className="col-12 col-md-12 col-lg-8" style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div className="main-title pt-2 pb-2">
                                                        <h1>{name}</h1>
                                                        <p>{description }</p>
                                                    </div>
                                                    <div className="menu-price-book">
                                                        <div className="price-book-divide d-flex justify-content-between ">
                                                            <a href="https://bit.ly/3CpWfO2">
                                                                <button className="btn btn-primary">Solicitar Orçamento</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                             
                        </div>
                    </div>
                </div>
            </div>
        </ ContainerGallery>
    )
}

export default GalleryReact;
