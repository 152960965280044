const Menu = [
     {
        id: 1,
        image: "/images/products/extintores.webp",
        name:"Extintores",
        category: "Extintores",
        description: 'Temos extintores do tipo: Água, Gás carbonico, Pó químico B/C, Pó químico A/B/C, espuma mecânica entre outros'
    },
    {
        id: 2,
        image: "/images/products/mangueira-rede-hidrante.webp",
        name:"Mangueira de hidrante",
        category: "Rede de Hidrante",
        description: 'Mangueira de hidrante tipo 1, 1.1/2 (1,5) entre outras. '
    },
    {
        id: 3,
        image: "/images/products/placa-de-rota-de-fuga.webp",
        name:"Placa rota de fuga",
        category: "Placa Rota Fuga",
        description: 'Placa de Sinalização de Evacuação e Rota de Fuga Tipo “Saída” Fotoluminescente (Fica Acesa no Escuro). Produto em conformidade com a NBR13434. '
    },
     {
        id: 4,
        image: "/images/products/recarga-extintores.webp",
        name:"recarga-extintores",
        category: "Recarga Extintores",
        description: 'Recarga de extintores de Água, Pó BC e Co2. '
    },
      {
        id: 5,
        image: "/images/products/sinalizacao-de-extintores.webp",
        name:"Sinalização Extintores",
        category: "Sinalização Extintores",
        description: "A sinalização precisa ser implantada e respeitada adequadamente para que haja sucesso, tendo como função informar com clareza e facilidade a localização, posição e sinalização do extintor."
    },
       {
        id: 6,
        image: "/images/products/suporte-para-extintores.webp",
        name:"Suporte para Extintores",
        category: "Suporte para Extintores",
        description: 'Ter um suporte para extintores vai assegurar que o extintor de incêndio permaneça na posição adequada, evitando o contato direto com o solo, sendo mais fácil de ser usado em casos de emergência.'
    }
];



export default Menu;