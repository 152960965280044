import { FaWhatsapp } from "react-icons/fa";
import { ButtonWhatsApp } from "./styles";

function FloatingButtonWhatsApp() {
    return (
        <ButtonWhatsApp
            whileInView="visible"
            initial="initial"
            viewport={{ once: true }}
            variants={{
            visible: {
                opacity: 1,
                y: 0,
                transition: { duration: 1, delay: 0.3 },
            },
            }}
        >
            <a href="https://bit.ly/3CpWfO2" rel="noreferrer" target="_blank">
                <FaWhatsapp />
            </a>
        </ButtonWhatsApp>
    )

}

export default FloatingButtonWhatsApp;